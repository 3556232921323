export default {
  name: "productsFiltersTags",
  component() {
    return {
      open: false,
      selectedCount: 0,

      init() {
        const inputs = Array.from(
          this.$el.querySelectorAll("input[checked]"),
        ).length;
        this.selectedCount = inputs;
      },
    };
  },
};
