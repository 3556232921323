export default {
  name: "productsFilters",
  component() {
    return {
      filtersOpen: false,
      selectedCount: 0,

      init() {
        const inputs = Array.from(this.$el.querySelectorAll("input"));
        const selectedInputs = Array.from(
          this.$el.querySelectorAll("input[checked]"),
        ).length;
        this.selectedCount = selectedInputs;

        Array.from(this.$el.querySelectorAll("a[data-reset]")).forEach(
          (link) => {
            link.addEventListener("click", (e) => {
              e.preventDefault();
              localStorage.removeItem("products-filters-search");
              window.location = link.href;
            });
          },
        );

        inputs.forEach((e) => {
          e.addEventListener("change", () => {
            const search = new URLSearchParams();

            inputs
              .filter((input) => Boolean(input.checked))
              .forEach((input) => {
                search.append(input.name, input.value);
              });

            localStorage.setItem("products-filters-search", search);
            const href = new URL(window.location.href);
            href.search = search;
            window.location = href.toString();
          });
        });
      },
    };
  },
};
