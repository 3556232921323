export default {
  name: "global",
  store() {
    return {
      isMobileMenuVisible: false,
      isMinicartVisible: false,
      isPredictiveSearchVisible: false,
      cart: null,
      init() {
        this.initLiquidAJaxCart();
      },

      get bodyClasses() {
        let classes = [];

        if (this.isMobileMenuVisible) {
          classes.push("mobile-menu-visible");
        }

        if (this.isMinicartVisible) {
          classes.push("minicart-visible");
        }

        return classes || "";
      },

      openMobileMenu() {
        this.isMobileMenuVisible = true;
      },

      closeMobileMenu() {
        this.isMobileMenuVisible = false;
      },

      toggleMobileMenu() {
        this.isMobileMenuVisible = !this.isMobileMenuVisible;
      },

      initLiquidAJaxCart() {
        document.addEventListener("liquid-ajax-cart:request-end", (event) => {
          const { requestState, cart } = event.detail;

          if (requestState.requestType === "add") {
            this.isMinicartVisible = true;
          }

          this.cart = cart;
        });
      },
    };
  },
};
