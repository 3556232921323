import "liquid-ajax-cart";
import("../js/prodify.js");

import Alpine from "alpinejs";
import AlpineCollapse from "@alpinejs/collapse";
import AlpineFocus from "@alpinejs/focus";
import AlpineMorph from "@alpinejs/morph";
import AlpineGlobals from "../js/alpine/index.js";

const hasBodyClass = (className) => {
  return document.body.classList.contains(className);
};

// Register and initialize AlpineJS
window.Alpine = Alpine;

Alpine.plugin([AlpineCollapse, AlpineFocus, AlpineMorph]);
AlpineGlobals.register(Alpine);
Alpine.start();
