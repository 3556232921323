export default {
  name: "collectionLink",
  component() {
    return {
      init() {
        const savedSearch = localStorage.getItem("products-filters-search");

        if (savedSearch) {
          const href = new URL(this.$el.href);
          href.search = savedSearch;
          this.$el.href = href.toString();
        }
      },
    };
  },
};
